import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "col-xl-4" }
const _hoisted_3 = { class: "col-xl-4" }
const _hoisted_4 = { class: "col-xl-4" }
const _hoisted_5 = { class: "row g-5 g-xl-8" }
const _hoisted_6 = { class: "col-xl-4" }
const _hoisted_7 = { class: "col-xl-4" }
const _hoisted_8 = { class: "col-xl-4" }
const _hoisted_9 = { class: "row g-5 g-xl-8" }
const _hoisted_10 = { class: "col-xl-6" }
const _hoisted_11 = { class: "col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListsWidget1 = _resolveComponent("ListsWidget1")!
  const _component_ListsWidget2 = _resolveComponent("ListsWidget2")!
  const _component_ListsWidget3 = _resolveComponent("ListsWidget3")!
  const _component_ListsWidget4 = _resolveComponent("ListsWidget4")!
  const _component_ListsWidget5 = _resolveComponent("ListsWidget5")!
  const _component_ListsWidget6 = _resolveComponent("ListsWidget6")!
  const _component_ListsWidget7 = _resolveComponent("ListsWidget7")!
  const _component_ListsWidget8 = _resolveComponent("ListsWidget8")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_ListsWidget1, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_ListsWidget2, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_ListsWidget3, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_ListsWidget4, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ]),
      _createVNode("div", _hoisted_7, [
        _createVNode(_component_ListsWidget5, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ]),
      _createVNode("div", _hoisted_8, [
        _createVNode(_component_ListsWidget6, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ])
    ]),
    _createVNode("div", _hoisted_9, [
      _createVNode("div", _hoisted_10, [
        _createVNode(_component_ListsWidget7, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ]),
      _createVNode("div", _hoisted_11, [
        _createVNode(_component_ListsWidget8, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ])
    ])
  ], 64))
}